<template>
  <div class="page">
    <div class="content-box section">
      <easy-card title="基础信息">
        <icon-button slot="extra" icon="return" @click.native="goBack" />

        <el-descriptions :column="3">
          <el-descriptions-item>
            <template slot="label"> 标题</template>
            {{ publishData.title }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 适用业务类型</template>
            {{
              publishData.businessType == 0
                ? "通用"
                : publishData.businessType == 1
                  ? "停车券"
                  : publishData.businessType == 2
                  ? "洗车券"
                  : "充电券"
            }}
          </el-descriptions-item>
          <!-- <el-descriptions-item>
            <template slot="label"> 结算方</template>
            {{ publishData.paySettleAccountName }}
          </el-descriptions-item> -->
          <el-descriptions-item>
            <template slot="label"> 结算类型</template>
            {{ publishData.isSettle == 0?"参与结算":"不参与结算" }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 承担方</template>
            {{ publishData.undertaker }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 上架时间</template>
            {{ publishData.startTime }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 下架时间</template>
            {{ publishData.endTime }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 备注</template>
            {{ publishData.remark }}
          </el-descriptions-item>
        </el-descriptions>
      </easy-card>
      <!-- publishData.couponPublishRule.faceAmount -->
      <easy-card title="优惠券规则">

        <el-descriptions :column="3">

          <el-descriptions-item>
            <template slot="label"> 优惠券额度</template>
            {{ publishData.effectiveTime }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 使用金额限制</template>
            大于{{ publishData.amountLimit }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 有效期</template>
            {{ publishData.effectiveTime }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 单次获券张数</template>
            {{ publishData.singleAccessRestriction }}
          </el-descriptions-item>

        </el-descriptions>
      </easy-card>

      <!-- <easy-card title="执行信息">
        <el-descriptions :column="2" border>
          <el-descriptions-item>
            <template slot="label"> 领取量</template>
            {{ publishData.receviedCount }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 使用量</template>
            {{ publishData.useCount }}
          </el-descriptions-item>
        </el-descriptions>
      </easy-card> -->
    </div>
  </div>
</template>


<script>
import { getSendCouponInfo } from "@/api/coupon";

export default {
  data() {
    return {
      publishData: [],
      parkingLotIds: [],
      couponPublishRule: [], //优惠券发行规则
    };
  },
  methods: {
    //返回停车订单页面
    goBack() {
      this.$router.back();
    },
    //根据id查询发行详情
    getCouponPublishRuleVO(id) {
      getSendCouponInfo({ id }).then((res) => {
        if (res && res.code === 30 && res.result) {
          this.publishData = res.returnObject;
          // const parkingLotIds = JSON.parse(res.returnObject.couponPublishRule.parkingLotIds);
          // this.parkingLotIds = parkingLotIds.join(",");
          this.couponPublishRule = res.returnObject.couponPublishRule;
        }
      });
    },
  },
  mounted() {
    const id = this.$route.query.id;
    this.getCouponPublishRuleVO(id);
  },
};
</script>

<style lang="less" scoped>
.record-tilte {
  margin: 14px 0;
}
</style>
